@tailwind base;
@tailwind components;
@tailwind utilities; 

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');


@layer base {
  body {
      @apply font-[Raleway];
  }
  li {
      @apply px-4;
      @apply cursor-pointer
  }
}

.content-div{
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  height: 300px;
}

.transition{
  transition: all 0.25s cubic-bezier(0.28, 0.15, 0, 2.1)
}

.icon{
  color: rgb(59 130 246);
}
::-moz-selection {
  /* Code for Firefox */
  color: black;
  background: rgb(59 130 246);
}

::selection {
  color: black;
  background: rgb(59 130 246);
}
